import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp, faChevronCircleDown, faBook, faClock, faMap, faUserGroup, faGear, faBell } from '@fortawesome/free-solid-svg-icons';
import { Row, Col  } from 'react-bootstrap';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const ArrowContainer = styled.div`
  justify-content: end;
  align-items: end;
  width:100%;
`;

const TextCenter = styled.div`
  justify-content: start;
  text-align:start;
  width:100%;
  height:auto;
`;

const NormalDiv = styled.div`
  padding:0;
  margin:0;
  max-height: ${props => (props.isOpen ? 'auto' : '45px')};
  padding:0 0 10px 0; // top right bottom left
`;

const NavLinkDiv = styled.div`
  cursor: pointer;
`;



function SubMenu({restaurant_id}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isTablesOpen, setIsTablesOpen] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfo) {
      navigate(`/login/${'null'}/${'null'}`);
    }
  }, [dispatch, navigate, userInfo]);

  const crudManagerPermissions = useSelector(state => state.crudManager)
  const {  managerInfo  } = crudManagerPermissions
  return (
    <>
      <button className={`menu-button ${isOpen ? 'rotate' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <FontAwesomeIcon icon={faChevronCircleUp} size="6x" style={{color: '#8FEBC5'}} />
      </button>
      <div className={`modern-menu ${isOpen ? 'show' : ''}`}>
        <div className="menu-top"></div>
        <div className={`${isOpen ? 'activate_scroll' : ''}`}>
        <Nav defaultActiveKey={`/reservationfromrestaurant/${restaurant_id}/null/filter`} className="flex-column">
          <NavLink className="nav-link" to={`/reservationfromrestaurant/${restaurant_id}/null/filter`}>
          <Row>
              <Col xs={2}>
              <FontAwesomeIcon size='lg' icon={faBook}/> 
              </Col>
                <Col xs={6}>
                <TextCenter>
                  Reservas
                </TextCenter>
                </Col>
                <Col xs={3}>
                </Col>
          </Row>
          </NavLink>
          <NavLink className="nav-link" to={`/time/${restaurant_id}`}>
          <Row>
              <Col xs={2}>
              <FontAwesomeIcon size='lg' icon={faClock}/> 
              </Col>
                <Col xs={6}>
                <TextCenter>
                  Horários
                </TextCenter>
                </Col>
                <Col xs={3}>
                </Col>
          </Row>
          </NavLink>
          {!managerInfo || (managerInfo && managerInfo.permissions === 'full_access') ?
            <>
             <NormalDiv isOpen={isSubmenuOpen} onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}>
            <NavLinkDiv className="nav-link" >
              <Row>
              <Col xs={2}>
                <FontAwesomeIcon size='lg' icon={faClock}  />
              </Col>
                <Col xs={6}>
                <TextCenter>
                   Horários
                </TextCenter>
                </Col>
                <Col xs={3}>
                  <ArrowContainer>
                  <div className={`arrow-icon ${isSubmenuOpen ? "rotate-menu-arrow" : ""}`}>
                    <FontAwesomeIcon 
                      icon={faChevronCircleDown} 
                      size='lg'
                      color="#4ca974"
                    />
                  </div>
                  </ArrowContainer>
                </Col>
              </Row>
            </NavLinkDiv>
            {isSubmenuOpen && (
              <>
               <NavLink className="nav-link" to={`/RestaurantTimeManagement/${restaurant_id}`}>
                  Turnos
                </NavLink>
                <NavLink className="nav-link" to={`/RestaurantOpenedOrLimitedExceptions/${restaurant_id}`}>
                  Fechar/limitar dias
                </NavLink>
                <NavLink className="nav-link" to={`/RestaurantEstimatedTimes/${restaurant_id}`}>
                  Estimativa de tempo por refeição
                </NavLink>
              </>

            )}
          </NormalDiv>
          
          <NormalDiv isOpen={isTablesOpen} onClick={() => setIsTablesOpen(!isTablesOpen)}>
            <NavLinkDiv className="nav-link">
              <Row>
              <Col xs={2}>
                <FontAwesomeIcon icon={faMap} />
              </Col>
                <Col xs={6}>
                <TextCenter>
                  Zonas e mesas
                </TextCenter>
                </Col>
                <Col xs={3}>
                  <ArrowContainer>
                  <div className={`arrow-icon ${isTablesOpen ? "rotate-menu-arrow" : ""}`}>
                    <FontAwesomeIcon 
                      icon={faChevronCircleDown} 
                      size='lg'
                      color="#4ca974"
                    />
                  </div>
                  </ArrowContainer>
                </Col>
              </Row>
            </NavLinkDiv>
            {isTablesOpen && (
              <>
                <NavLink className="nav-link" to={`/RestaurantZonesTablesManagement/${restaurant_id}`}>
                  Zonas e mesas
                </NavLink>
                <NavLink className="nav-link" to={`/restaurant_connected_tables/${restaurant_id}`}>
                  Conecção de mesas
                </NavLink>
                <NavLink className="nav-link" to={`/place_design/${restaurant_id}`}>
                  Design de zona
                </NavLink>
              </>
            )}
          </NormalDiv>
         
          <NavLink className="nav-link" to={`/restaurantclients/${restaurant_id}`}>
          <Row>
            <Col xs={2}>
              <FontAwesomeIcon size='lg' icon={faUserGroup} />
            </Col>
            <Col xs={6}>
              <TextCenter>
               Clientes
              </TextCenter>
            </Col>
            <Col xs={3}>
            </Col>
          </Row>
            
          </NavLink>

          <NavLink className="nav-link" to={`/restaurant_notifications/${restaurant_id}`}>
          <Row>
            <Col xs={2}>
            <FontAwesomeIcon size='lg' icon={faBell} />
            </Col>
            <Col xs={6}>
              <TextCenter>
                Notificações
              </TextCenter>
            </Col>
            <Col xs={3}>
            </Col>
          </Row>
            
          </NavLink>
          
          <NavLink className="nav-link" to={`/restaurantsettingsupdate/${restaurant_id}`}>
            <Row>
              <Col xs={2}>
              <FontAwesomeIcon size='lg' icon={faGear} />
              </Col>
              <Col xs={6}>
                <TextCenter>
                  Definições
                </TextCenter>
              </Col>
              <Col xs={3}>
              </Col>
            </Row>
          </NavLink>
            </>
          :
            <>
            </>
          }
         
          
        </Nav>
        </div>
        
      </div>
    </>
  );
}

export default SubMenu;
