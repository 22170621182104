import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { fetchRestaurantDetails, tablesToChange as fetchTablesToChange, changeTables } from '../actions/restaurantActions';
import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablesModal  from '../components/ChangeTables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark, faFloppyDisk, faTriangleExclamation, faShareFromSquare, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import {
  fetchReservationBlockedDays,
  fetchReservationBlockedWeekdays,
  fetchReservationShift,
  fetchOpenTime,
  fetchCreateReservation,
  fetchReservationLimitPeopleBaby,
  fetchEditReservation,
  fetchReservationCustomerMenu,
  checkIfrestaurantIsOpenToday,
  getRestaurantTimeZone,
  ResetReservation,
  fetchResetReservation,
  fetchCancelReservation,
  fetchReservationActive,  
} from '../actions/reservationsActions';
import { cancelledStatuses } from '../components/ReservationStatus';
import styled from 'styled-components';
import ShiftSelect from '../components/ShiftSelect'
import PushNotificationForReservation from '../components/PushNotificationForReservation'
import SelectablePopUpOptions from '../components/SelectablePopUpOptions'
import BabyChairSelect from '../components/BabySelection'
import CustomDatePicker from '../components/CustomDatePicker';
import CustomSelect from '../components/SelectableCustom';
import {getDialCode, getCountryCode} from '../components/CountryCode';
import Loader from '../components/Loader'
import DropDownButton from '../components/DropDownButton'
import Messages from '../components/Messages'
import TermsAndConditions from '../components/TermsAndConditions'
import ScrollInputOptions from '../components/ScrollInputOptions';
import StepTwoReservations from '../components/StepTwoReservations';
import StepThreeReservations from '../components/StepThreeReservations';
import AnimatedCheck from '../components/CheckMarkAnimation'
import { ta } from 'date-fns/locale';
import { isValidNumber } from 'libphonenumber-js';
import {getReservationStatusTranslation} from '../components/ReservationStatus'


const BubleText = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  padding: 0 5px; /* Left and right padding */
  border-radius: 5px;
  background-color: ${props => (props.status === 'Cancelled' || 
                      props.status === 'Cancelled_By_Customer' ||
                      props.status === 'Cancelled_By_Restaurant' ||
                      props.status === 'Did_Not_Attend' ? '#e8733c'
                      : props.status === 'Cancelled_By_Expired_Code' ? '#dcb1e0'
                      : props.status === 'Pending_from_customer_Warning' ? '#d6a7c1'
                      : props.status === 'Edited' ? '#7e538a'
                      : props.status === 'Pending_from_restaurant' ? '#cacf44'
                      : props.status === 'Pending_from_customer' ? '#959657'
                      : props.status === 'Late' ? '#ebb052'
                      : '#58c49f')};
  color:white;
`;


const InputOverlay = styled.input`
  position: absolute;
  top: 33px;
  font-size:16px;
  left: 105px; 
  height: 36px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
`;


const Wrapper = styled(Container)`
  max-width: 500px;
  margin: 10px auto;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height:auto;
`;

const ModalBackgroundCheck = styled.div`
  width:50px;
  height:50px;
  z-index: 1002;
`;

const ContainerCheck = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center; // for vertical centering
  justify-content: center; // for horizontal centering
`;


const ModalBackground = styled.div`
  position: fixed;
  z-index: 1003;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBackgroundBottom = styled.div`
  position: relative;
  padding-right:10px;
  display: flex;
  justify-content: end;
  align-items: center;
  width:100%;
`;



const FixedWrapper = styled.div`
  position:relative;
  overflow-y: auto;
  z-index: 1002;
  border-radius:5px;
  height: 70%;
  max-height:500px;
  min-width:200px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const PopupContainer = styled.div`
  display:flex;
  margin:20px;
  min-width:200px;
  max-width: 500px;
  flex-wrap: wrap;
  position: absolute;
  padding: 10px 10px 10px 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 75%;
  z-index: 1001;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  top:10px;
`;

const Title = styled.h3`
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledCard = styled.div`
  border-radius:5px;
  max-width: 500px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width:100%;
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;
//shift select custom 
const CustomSelectWrapper = styled.div`
  display: row;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    max-width: 100%;
    box-sizing: border-box;
    overflow:hidden;
  }
`;
// Date Field
const DateButton = styled.div`
  cursor:pointer;
  display:flex;
  align-items: center;
  justify-content:center;
  padding: 0;
  height:38px;
  border-radius: 5px;
  border: 1px solid gray;
  width:150px;
  overflow:hidden;
`;
const DateButtonLeft = styled.div`
  display:flex;
  align-items: center;
  justify-content:center;
  padding: 0;
  background-color: #8FEBC5;
  height:100%;
  width:50px;
  border-right: 1px solid gray; 
  cursor:pointer;
`;
const DateButtonRight = styled.div`
  display: flex;  
  align-items: center;  
  justify-content:center;
  font-size: 15px;
  color: gray;
  text-align:center;
  width:100px;
  cursor:pointer;
`;

const DivFlexEnd = styled.div`
  display: flex;  
  width:100%;
  justify-content:end;
`;

const DivFlexStart = styled.div`
  display: flex; 
  align-items:center; 
  width:100%;
  justify-content:start;
`;

const DivFlexMiddle = styled.div`
  display: flex;  
  width:100%;
  justify-content:center;
`;

const LimitationsExtras = styled.div`
  display: flex;
  align-items:center;   
  padding:5px;
  margin-top:10px;
  border-radius:5px;
  gap: 10px;
  border: 1px solid gray;
  justify-content:center;
  cursor:pointer;
  color: ${props => (props.ignoreLimitations) ? '#ffffff'  : 'black'};
  background-color: ${props => (props.ignoreLimitations) ? '#58d18c'  : 'white'};
`;

const Extras = styled.div`
  display: flex;
  align-items:center;   
  padding:5px;
  margin-top:10px;
  border-radius:5px;
  gap: 10px;
  border: 1px solid gray;
  max-width:100px;
  justify-content:center;
  cursor:pointer;
  color: ${props => (props.isExtrasOpen) ? '#ffffff'  : 'black'};
  background-color: ${props => (props.isExtrasOpen) ? '#58d18c'  : 'white'};
`;

const ExtrasInside = styled.div`
  padding:5px;
  width:100%;
  margin-top:10px;
  border-radius:5px;
  border: 1px solid gray;
  cursor:pointer;
  color: ${props => (props.status) ? '#ffffff'  : 'black'};
  background-color: ${props => (props.status) ? '#58d18c'  : 'white'};
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CustomDateContainer = styled.div`
  position: fixed;  // fixed will keep it centered even when the page is scrolled
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);  // centers the div
  z-index: 5000;
`;

const ModalBackgroundToCustomDate = styled.div`
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;



function getTableInfo(selectedTablesList, allTablesList) {
  let tableInfo = {};


  
  Object.keys(selectedTablesList).forEach(zoneName => {
    const tableNumbers = selectedTablesList[zoneName].map(table => table.number);
    
    allTablesList.forEach(tablesInZone => {
      if (tablesInZone[0] === zoneName) {
        tableNumbers.forEach(tableNumber => {
          const tableDetails = tablesInZone.find(table => table.split(';')[0] === tableNumber);
          if (tableDetails) {
            if (tableInfo[zoneName]) {
              tableInfo[zoneName].push(tableDetails);
            } else {
              tableInfo[zoneName] = [tableDetails];
            }
          }
        });
      }
    });
  });


  // Convert the object into a list of lists
  let tableInfoList = [];
  Object.keys(tableInfo).forEach(zone => {
    tableInfoList.push([zone, ...tableInfo[zone]]);
  });

  return tableInfoList;
}


function listToSelectedTablesFormat(zoneName, tableNumbers) {
  if (!Array.isArray(tableNumbers)) {
    throw new Error("Expected parameter to be an array");
  }

  const tables = tableNumbers.map(tableNumber => ({
    number: tableNumber,
    status: "0",
  }));

  return {
    [zoneName]: tables,
  };
}
const ReservationScreen = ({from_restaurant=false, creating=true, 
                           isEditModalOpen=false, setIsEditModalOpen, 
                           restaurantId=false, token_from_restaurant=false,
                           handleCloseReservationModal, setCreating, reset, editModalRef,
                           setTokens}) => {
  const id = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState( token_from_restaurant  ? token_from_restaurant: id.token? id.token :  false);
  const [restaurant_id, setRestaurant_id] = useState(id.restaurant_id ? id.restaurant_id : restaurantId);
  const [startDate, setStartDate] = useState(null);
  const [startDateWS, setStartDateWS] = useState(false);
  const [shifts, setShift] = useState('');
  const [numPeople, setNumPeople] = useState(0);
  const [babyChairs, setBabyChairs] = useState(false);
  const [numBabyChairs, setNumBabyChairs] = useState(0);
  const [time, setTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState('');
  const [message, setMessage] = useState('');
  const maxMessageLength = 150;
  const [accessability, setAccessability] = useState('');
  const [accessabilities, setAccessabilities] = useState(['Cadeira de Rodas']);
  const [tablesUsed, setTablesUsed] = useState([]);
  const [usedTablesDic, setUsedTablesDic] = useState({});
  const [fullName, setFullName] = useState('');
  const [placeOfTable, setPlaceOfTable] = useState('');
  const [placeOfTableWS, setPlaceOfTableWS] = useState(false);
  const [fourthStage, setFourthStage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [countryCode, setCountryCode] = useState("us");
  const [countryCodeNumber, setCountryCodeNumber] = useState('351');
  const [phone, setPhone] = useState('');
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');

  const [email, setEmail] = useState('');
  const [emailWS, setTheEmailWS] = useState(false);

  const [subscription, setSubscription] = useState({});
  const [cd, setCd] = useState('');
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsWS, setAcceptTermsWS] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [fullRestaurant, setFullRestaurant] = useState(false);
  const [isExtrasOpen, setIsExtrasOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isAccessOpen, setIsAccessOpen] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [editingStartFalse, setEditingStartFalse] = useState(true);
  const [showTablesModal, setShowTablesModal] = useState(false);
  const [showModal, setShowModal] = useState(false); // cancel modal
  const [ignoreLimitations, setIgnoreLimitations] = useState(false);
  const [selectedTablesSave, setSelectedTablesSave] = useState(false);
  const [reservationObj, setReservationObj] = useState({});


  const [update, setUpdate] = useState(0);

  const reservationCancel = useSelector(state => state.cancelReservation)
  const { cancelMessage, isLoadingCancel } = reservationCancel

  


  useEffect(() => {
    console.log('update state: ', update)
  },[update])

  useEffect(() => {
    if (!from_restaurant) {
      dispatch(fetchReservationActive());
    }
  },[from_restaurant])



  useEffect(() => {
    if (Array.isArray(tablesUsed) && tablesUsed[0] == '' && tablesUsed.length > 1) {
      setTablesUsed(tablesUsed.slice(1, tablesUsed.length))
    }
  },[tablesUsed])
  
  useEffect(() => {
    if (token) {
      setEditingStartFalse(true)
    } else {
      setEditingStartFalse(true)
    }
  },[token, editingStartFalse])

  useEffect(()=> {
    if (restaurantId) {
      setRestaurant_id(restaurantId);
    }
    
  }, [restaurantId])

  useEffect(()=> {
    if (creating) {
      setToken('');
      setTime('');
      setEstimatedTime('');
      setBabyChairs(false);
      setNumBabyChairs(0);
      setMessage('');
      setEmail('');
      setNumPeople(0);
      setTablesUsed([]);
      setSelectedTablesSave('');
      setAccessability('');
      setPlaceOfTable('');
      setUsedTablesDic({});
      if (from_restaurant) {
        setFullName('');
        setPhoneWithoutCountryCode('');
      }
    }
    if (from_restaurant) {
      setIgnoreLimitations(false);
    }
  }, [creating, reset, token, startDate]);

  const [fullNameWS, setFullNameWS] = useState(false);
  const [numPeopleWS, setNumPeopleWS] = useState(false);
  const [timeWS, setTimeWS] = useState(false);
  const [phoneWS, setPhoneWS] = useState(false);

  useEffect(() => {
    dispatch(checkIfrestaurantIsOpenToday(restaurant_id));
    dispatch(getRestaurantTimeZone(restaurant_id));
  }, [from_restaurant, restaurant_id]);

  useEffect(() => {
    dispatch(fetchReservationLimitPeopleBaby(restaurant_id, startDate, time));
  },[startDate, time, restaurant_id])



  const [currentStep, setCurrentStep] = useState(1);


  const editReservations = useSelector((state) => state.editReservation);
  const { editReservation, isLoadingEditReservation  } = editReservations;

  const openTimes = useSelector((state) => state.openTime);
  const { openTime, isLoadingOpenTime, errorOpenTime } = openTimes;

  const LimitsPB = useSelector((state) => state.reservationLimitsPeopleBaby);
  const { limitPeopleBaby } = LimitsPB;

  const reservation_createdd = useSelector((state) => state.createReservation);
  const { reservation_created, toastCreateUpdate , isLoading, error, createReservationFailed  } = reservation_createdd;

  const restaurantDetails = useSelector(state => state.restaurantDetails);
  const { restaurants } = restaurantDetails;

  const tablesToChange= useSelector((state) => state.availableTablesToChange);
  const { tables = [], isLoadingTables, errorTables } = tablesToChange;



  useEffect(() => {
    dispatch(fetchRestaurantDetails(restaurant_id));
  }, [dispatch, restaurant_id]);

  useEffect(() => {
    setToken(token_from_restaurant);
    setSelectedTablesSave(false);
    console.log('token from restaurant updated')
  },[token_from_restaurant]);

  const reservation_info = useSelector(state => state.reservationCustomersMenu)
  const {  reservation  } = reservation_info

useEffect(() => {
  if (!creating && token != '') {
    dispatch(fetchReservationCustomerMenu(token, 'no'));
  } else {
    dispatch(ResetReservation());
  }
},[creating, token]);

useEffect(() => {
  if (!creating && token != '') {
      if (reservation && restaurant_id !== reservation.restaurant) {
        setRestaurant_id(reservation.restaurant);
      }
  }
},[reservation]);

useEffect(() => {
  let phoneWithoutCountryCoda;
  if (reservation && reservation.phone_number) {
    const countryCodeLength = countryCodeNumber.length;
    let phoneNumber = reservation.phone_number;
    // Remove leading plus if it exists
    if (phoneNumber[0] === '+') {
      phoneNumber = phoneNumber.slice(1);
    }
    // Remove leading country code number
    phoneWithoutCountryCoda = phoneNumber.slice(countryCodeLength);
  }
  
  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
  
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  if (reservation && !creating && !deepEqual(reservation, reservationObj) || reservation && fullName !== reservation.full_name) {
    setFullName(reservation.full_name);
    const dateObject = new Date(reservation.date);
    setStartDate(dateObject);
    setShift(reservation.shift);
    if (reservation.table_place_preference !== null || reservation.table_place_preference !== '') {
      setPlaceOfTable(reservation.table_place_preference.place_of_table);
    } else {
      setPlaceOfTable('');
    }
    console.log('time: ', time);
    setTime(reservation.time);
    setEstimatedTime(reservation.estimatedtime);
    setMessage(reservation.message);
    setNumPeople(reservation.number_of_people);
    setTablesUsed(reservation.tablesused.split(','))
    setCountryCodeNumber(reservation.country_code)
    setPhone(reservation.phone_number)
    setPhoneWithoutCountryCode(phoneWithoutCountryCoda);
    setEmail(reservation.email)
    setAccessability(reservation.accessability === 'wheel_chair' ? 
    'Cadeira de Rodas' : 'not_needed')
    if (reservation.baby_chair > 0){
      setBabyChairs(true);
      setNumBabyChairs(reservation.baby_chair);
    } else {
      setBabyChairs(false);
    }
    if (reservation.accessability != 'not_needed'){
      setIsAccessOpen(true);
    } else {
      setIsAccessOpen(false);
    }
    let usedTablesss = reservation.tablesused.split(',');
    let selectedTablesFormat = {}
    if (Array.isArray(usedTablesss)) {
      selectedTablesFormat = listToSelectedTablesFormat(reservation.table_place_preference.place_of_table, usedTablesss);
    } 
    setUsedTablesDic(selectedTablesFormat)
    let usedTabless;
      if (  ignoreLimitations && creating || reservation.tablesused === '') {
        usedTabless = 'no_tables';
      } else {
        usedTabless = reservation.tablesused;
      }
    const inputDate = new Date(dateObject);
    dispatch(fetchTablesToChange(restaurant_id, 
      `${inputDate.getFullYear()}-${String(inputDate.getMonth() + 1).padStart(2, '0')}-${String(inputDate.getDate()).padStart(2, '0')}`, 
      reservation.time, reservation.table_place_preference.place_of_table, 
      usedTabless, reservation.number_of_people));
  setReservationObj(reservation);
  }
},[reservation, token, creating]);

    //() => handleShowTablesModal(reservation.restaurant, 
    //dateFilter, reservation.time, reservation.table_place_preference.id, 
    //reservation.tablesused, reservation.table_place_preference.place_of_table, 
    //reservation.token, reservation.estimatedtime)
    // Adjust the handleShowTablesModal function

    const handleShowTablesModal = (show=true) => {
      let usedTablesss = tablesUsed;
      let selectedTablesFormat = {}
      if (Array.isArray(usedTablesss)) {
        selectedTablesFormat = listToSelectedTablesFormat(placeOfTable, usedTablesss);
      } 
      setUsedTablesDic(selectedTablesFormat)
      let usedTabless;
      if (  ignoreLimitations && creating || reservation.tablesused === '') {
        usedTabless = 'no_tables';
      } else {
        usedTabless = reservation.tablesused;
      }
      const inputDate = new Date(startDate);
      dispatch(fetchTablesToChange(restaurant_id, 
        `${inputDate.getFullYear()}-${String(inputDate.getMonth() + 1).padStart(2, '0')}-${String(inputDate.getDate()).padStart(2, '0')}`, 
        time, placeOfTable, usedTabless, numPeople));
      if (show) {
        setShowTablesModal(true);
      }
    };

    const handleModalSave = (selectedTables) => {
      const fullTableInfo = getTableInfo(selectedTables, tables);
      dispatch(changeTables(token, fullTableInfo, restaurant_id));
      setShowTablesModal(false);

      toast('Reserva Editada', { autoClose: 1000, zIndex: 1000 });
    setShowCheck(true); // Show the check mark animation
      setTimeout(() => {
        setShowCheck(false); // Hide the check
      }, 1700);
    dispatch(fetchEditReservation(token, restaurant_id, startDate, 
                                  numPeople, shifts, time, fullName, 
                                  phone, countryCodeNumber, babyChairs ? numBabyChairs : 0, 
                                  message, placeOfTable, subscription, 
                                  isAccessOpen ? 
                                  (accessability === 'Cadeira de Rodas' ? 'wheel_chair' : 'cane') 
                                  : 'not_needed',
                                  ignoreLimitations, fullTableInfo, from_restaurant));
  if (!creating && token != '') {
    dispatch(fetchReservationCustomerMenu(token, 'no'));
    } else {
      dispatch(ResetReservation());
    }
    };


useEffect(() => {
  if (reservation_created && !from_restaurant) {
    const countryCodeLength = reservation_created.country_code.length;
    let phoneNumber = reservation_created.phone_number;
    // Remove leading plus if it exists
    if (phoneNumber[0] === '+') {
      phoneNumber = phoneNumber.slice(1);
    }
    // Remove leading country code number
    const phoneWithoutCountryCode = phoneNumber.slice(countryCodeLength);
    if (fullName === '') {
      setPhoneWithoutCountryCode(phoneWithoutCountryCode);
      setFullName(reservation_created.full_name);
      setEmail(reservation_created.email);
    }
  }
},[reservation_created]);

  const goForward = () => {
    if (currentStep === 1 && startDate != null) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2) {
      if (time != '' && placeOfTable != '' && fullRestaurant === false ) {
        setCurrentStep(currentStep + 1);
        setTimeWS(false);
        setPlaceOfTableWS(false);
      } else if (time === '') {
        setTimeWS(true);
      } else if (placeOfTable === '') {
        setPlaceOfTableWS(true);
      }
    } else if (startDate != null) {
      setCurrentStep(currentStep + 1);
    }
    if (startDate === null) {
      setStartDateWS(true)
    } else {
      setStartDateWS(false)
    }
  };

  const goBack = () => {
    if (currentStep === 3){
      setErrorMessage(false)
    }
    setCurrentStep(currentStep - 1);
  };

  const verifyEmptyFields = (verify_for_edition=false) => {
    console.log('email:', email)
    console.log('time:', time)
    console.log('fullName:', fullName)
    console.log('placeOfTable:', placeOfTable)
    console.log('phone:', phone)
    console.log('numPeople:', numPeople)
    let verifyFields = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email) && !from_restaurant){
      setTheEmailWS(true);
      verifyFields = false;
    } else {
      setTheEmailWS(false);
    }
    if (time === '' || null) {
      verifyFields = false;
      setTimeWS(true);
    } else {
      setTimeWS(false);
    }
    if ( fullName === '') {
      verifyFields = false;
      setFullNameWS(true);
    } else {
      setFullNameWS(false);
    }
    if ( placeOfTable === '') {
      verifyFields = false;
      setPlaceOfTableWS(true);
    } else {
      setPlaceOfTableWS(false);
    }
    console.log('from_restaurant isss : ', from_restaurant)
    if (isValidNumber('+' + phone) && !from_restaurant) {
        console.log('from_restaurant isss : ', from_restaurant)
        setPhoneWS(false);
    } else {
        verifyFields = false;
        setPhoneWS(true);
    }

    if ( numPeople === 0) {
      verifyFields = false;
      setNumPeopleWS(true);
    } else {
      setNumPeopleWS(false);
    }
    
    if (!babyChairs) {
      setNumBabyChairs(0);
    }
    if (!verify_for_edition) {
      if ( acceptTerms === false && from_restaurant === false) {
        verifyFields = false;
        setAcceptTermsWS(true);
      } else {
        setAcceptTermsWS(false);
      }
    }
    if (from_restaurant == true) {
      verifyFields = true;
    }
    return verifyFields;
  }

  const handleSubmit = (e) => {
    console.log('working')
    e.preventDefault();
    let verifyFields = verifyEmptyFields();
    if (verifyFields === true) {
      setCurrentStep(currentStep + 1);
      if (creating === true && from_restaurant) {
        setCreating(false);
      }
      setShowCheck(true); // Show the check mark animation
      setTimeout(() => {
        setShowCheck(false); // Hide the check
      }, 1700);
      const fullTableInfo = getTableInfo(selectedTablesSave, tables);
      dispatch(fetchCreateReservation(restaurant_id, startDate, numPeople, 
                                      shifts, time, fullName, email, phone, countryCodeNumber, 
                                      babyChairs ? numBabyChairs : 0,  
                                      isMessageOpen ? message : '' , 
                                      placeOfTable, subscription,
                                      isAccessOpen ? 
                                      (accessability === 'Cadeira de Rodas' ? 'wheel_chair' : 'cane') 
                                      : 'not_needed', 
                                      from_restaurant, ignoreLimitations, fullTableInfo));
                                      
    setIsEditModalOpen(false);
    setTokens('');
    }
  };

  const handleEdit = (e) => {
    console.log('phone inside edit: ', phone)
    let verifyFields = verifyEmptyFields(true);
    if (verifyFields === true) {
    toast('Reserva Editada', { autoClose: 1000, zIndex: 1000 });
    setShowCheck(true); // Show the check mark animation
      setTimeout(() => {
        setShowCheck(false); // Hide the check
      }, 1700);
    e.preventDefault();
    setCurrentStep(currentStep + 1);
    let fullTableInfo = false;
    if (selectedTablesSave && Object.keys(selectedTablesSave).length !== 0 && from_restaurant) {
      fullTableInfo = getTableInfo(selectedTablesSave, tables);
    }
    console.log('phone inside edit: ', phone)
    dispatch(fetchEditReservation(token, restaurant_id, startDate, 
                                  numPeople, shifts, time, fullName, 
                                  phone, countryCodeNumber, babyChairs ? numBabyChairs : 0, 
                                  message, placeOfTable, subscription, 
                                  isAccessOpen ? 
                                  (accessability === 'Cadeira de Rodas' ? 'wheel_chair' : 'cane') 
                                  : 'not_needed',
                                  ignoreLimitations, fullTableInfo, from_restaurant));
  if (!creating && token != '') {
    dispatch(fetchReservationCustomerMenu(token, 'no'));
    } else {
      dispatch(ResetReservation());
    }
    setIsEditModalOpen(!isEditModalOpen);
    setTokens('');
    }
  };


  useEffect(() => {
      dispatch(fetchOpenTime(restaurant_id, startDate, numPeople, token, ignoreLimitations));
  }, [dispatch, restaurant_id, startDate, numPeople, token, ignoreLimitations]);

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };
  const handlePlaceOfTable = (value) => {
    setTablesUsed([]);
    setPlaceOfTable(value);
  };
  const handleShiftChange = (value) => {
    setShift(value);
  };
  const handlePeopleChange = (value) => {
    setNumPeople(value);
  };
  const handleBabyChange = (value) => {
    setNumBabyChairs(value);
  };
  const handleBabyActive = (value) => {
    setBabyChairs(value);
  };
  const handleBabyReset = (value) => {
    setNumBabyChairs(0);
  };

  useEffect(() =>{
    if (reservation_created && currentStep >= 4 && reservation_created.token && !from_restaurant && createReservationFailed === false ) {
      navigate(`/customer_reservation_menu/${reservation_created.token}/await_confirmation`);
    }
  },[currentStep, reservation_created])

  const handleGoToReservation = (token) => {
    navigate(`/c/${token}/yes`);
  };
  const handleGoToReservationWhenEdited = () => {
    navigate(`/customer_reservation_menu/${editReservation.token}/await_confirmation`);
  };
  const handleGoBackToReservationsRestaurantMenu = () => {
    navigate(`/reservationfromrestaurant/${restaurant_id}`);
  };

  useEffect(() => {
    if (countryCodeNumber === '') {
      const detectedCountryCode = detectCountryCode();
      setCountryCode(detectedCountryCode);
      setCountryCodeNumber(getDialCode(detectedCountryCode));
    } else {
      setCountryCode(getCountryCode(countryCodeNumber));
    }
  }, [countryCodeNumber]);

  const detectCountryCode = () => {
    const userCountryCode = navigator.language.split("-")[1];
    const formattedCountryCode = userCountryCode ? userCountryCode.toLowerCase() : "us";
    // Add more specific checks if needed based on the origin or location detection mechanism
    return formattedCountryCode; // Default to 'PT' if the country code is not detected
  };

  const handleNotificationSubscription = (sub) => {
    setSubscription(sub);
  }

  useEffect(() => {
    // Check if countryCode and phone are not null or undefined before concatenating
    if (countryCodeNumber && phoneWithoutCountryCode.length >= 0) {
      setPhone(String(countryCodeNumber) + String(phoneWithoutCountryCode));
    }
  }, [phoneWithoutCountryCode, countryCodeNumber]);

  const handleTermsAndConditionsFalse = () => {
    setShowTermsAndConditions(false);
  };

  const MAX_CHARACTER_LIMIT = 10; 
  const handleInputPhoneChange = (e) => {
    const inputValue = e.target.value;
    console.log('phone input: ',inputValue)
    if (inputValue.length <= MAX_CHARACTER_LIMIT) {
      setPhoneWithoutCountryCode(inputValue);
    }
  };

  useEffect(() => {
    console.log('phone input true: ', phone)
  },[phone])

  const handleOpenDateModal = () => {
    setShowDateModal(true);
  };

  const handleCloseDateModal = () => {
    setShowDateModal(false);
  };

  useEffect(() => {
    if (openTime && Array.isArray(openTime.zones) && Array.isArray(openTime.shifts) && creating && from_restaurant) {
    let foundZoneWithoutF = false;
    let foundShiftWithoutF = false;

    // Check items in openTime.zones
    for (let zone of openTime.zones) {
        if (!zone.endsWith('/f')) {
            setPlaceOfTable(zone);
            foundZoneWithoutF = true;
            break;  // Exit the loop once you find the first item
        }
    }

    // Check items in openTime.shifts
    for (let shift of openTime.shifts) {
        if (!shift.endsWith('/f')) {
            foundShiftWithoutF = true;
            break;  // Exit the loop once you find the first item
        }
    }
    if (!foundZoneWithoutF){
      setPlaceOfTable('');
    }

    if (!foundZoneWithoutF && !foundShiftWithoutF) {
        // Assuming your other state setter function is setOtherState
        setFullRestaurant(true);
    } else {
      setFullRestaurant(false);
    }
    }
}, [openTime, numPeople]);

  const handleExtras = (value) => {
    setIsExtrasOpen(!isExtrasOpen);
  };

  const handleTablesUsedChange = (value) => {
    setTablesUsed(value);
  };

  const handleCancel = () => {
    if (reservation) {
      dispatch(fetchCancelReservation(reservation.token));
    }
    setIsEditModalOpen(false);
    setShowModal(false);
    setTokens('');
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  useEffect(() => {
    console.log('email: ', email)
    console.log('fullName: ', fullName)
  },[email, fullName])

  return (
    <>
    {showCheck &&
    <ContainerCheck>
      <ModalBackgroundCheck>
        <ToastContainer />
        <AnimatedCheck/>
      </ModalBackgroundCheck>
    </ContainerCheck>
    } 
    
    {!creating || from_restaurant === true ?
      <>
        {isEditModalOpen &&
        <SelectWrapper>
          <ModalBackground
          ref={editModalRef}
          onClick={handleCloseReservationModal}
        >
           <PopupContainer onClick={e => e.stopPropagation()}>
           <FontAwesomeIcon 
                  onClick={() => setIsEditModalOpen(false)} 
                  size="xl" icon={faRectangleXmark} style={{"--fa-primary-color": "#eb6434", 
                  "color": "#eb6434", 
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: 'pointer',
                  paddingRight: '3px',
                  }}
                />
              <Row style={{width: '100%', marginLeft: '10px'}}>
                <Col xs={12} className='d-flex justify-content-start'>
                <h5>{from_restaurant && creating ? 'Criar reserva': from_restaurant && !creating ? 'Editar reserva' : 'Esta a editar a sua reserva no'}</h5>
                </Col>
                <Col xs={12} className='d-flex justify-content-start'>
                <Title>{restaurants ? restaurants.restaurant_name : <Loader></Loader>}</Title>
                </Col>
                <Col xs={12} className='d-flex justify-content-start'>
                <BubleText status={ reservation && reservation.status}>{reservation && getReservationStatusTranslation(reservation.status)}</BubleText>
                </Col>
                
              </Row>
          
              <FixedWrapper>
              <StepTwoReservations
                startDate={startDate}
                DivFlexStart={DivFlexStart}
                DivFlexMiddle={DivFlexMiddle}
                DateButton={DateButton}
                handlePeopleChange={handlePeopleChange}
                handleOpenDateModal={handleOpenDateModal}
                DropDownButton={DropDownButton}
                limitPeopleBaby={limitPeopleBaby}
                handleShiftChange={handleShiftChange}
                numPeople={numPeople}
                openTime={openTime}
                CustomSelect={CustomSelect}
                shifts={shifts}
                setShift={setShift}
                placeOfTable={placeOfTable}
                setPlaceOfTable={setPlaceOfTable}
                SelectablePopUpOptions={SelectablePopUpOptions}
                handlePlaceOfTable={handlePlaceOfTable}
                handleTimeChange={handleTimeChange}
                time={time}
                setTime={setTime}
                Loader={Loader}
                fullRestaurant={fullRestaurant}
                Messages={Messages}
                ActionButtons={ActionButtons}
                goBack={goBack}
                goForward={goForward}
                buttonsFalse={true}
                true_create_false_edit={creating}
                editingStartFalse={editingStartFalse}
                setEditingStartFalse={(value) => setEditingStartFalse(value)}
                from_restaurant={from_restaurant}
                token={token}
                tablesUsed={tablesUsed}
                handleShowTablesModal={handleShowTablesModal}
                setIgnoreLimitations={setIgnoreLimitations}
                ignoreLimitations={ignoreLimitations}
                LimitationsExtras={LimitationsExtras}
                placeOfTableWS={placeOfTableWS}
                timeWS={timeWS}
                reservation={reservation}
                update={update}
                setUpdate={setUpdate}
              />

              <StepThreeReservations
                fullName={fullName}
                setFullName={setFullName}
                setEmail={setEmail}
                email={email}
                StyledPhoneInput={StyledPhoneInput}
                countryCode={countryCode}
                setCountryCodeNumber={setCountryCodeNumber}
                InputOverlay={InputOverlay}
                phoneWithoutCountryCode={phoneWithoutCountryCode}
                handleInputPhoneChange={handleInputPhoneChange}
                Extras={Extras}
                isExtrasOpen={isExtrasOpen}
                handleExtras={handleExtras}
                ExtrasInside={ExtrasInside}
                babyChairs={babyChairs}
                DivFlexStart={DivFlexStart}
                setBabyChairs={setBabyChairs}
                DivFlexEnd={DivFlexEnd}
                ScrollInputOptions={ScrollInputOptions}
                numBabyChairs={numBabyChairs}
                handleBabyChange={handleBabyChange}
                limitPeopleBaby={limitPeopleBaby}
                isMessageOpen={isMessageOpen}
                setIsMessageOpen={setIsMessageOpen}
                isAccessOpen={isAccessOpen}
                message={message}
                setMessage={setMessage}
                maxMessageLength={maxMessageLength}
                CustomSelect={CustomSelect}
                accessabilities={accessabilities}
                setIsAccessOpen={setIsAccessOpen}
                setAccessability={setAccessability}
                ActionButtons={ActionButtons}
                goBack={goBack}
                accessability={accessability}
                setShowTermsAndConditions={setShowTermsAndConditions}
                handleSubmit={handleSubmit}
                buttonsFalse={true}
                true_create_false_edit={creating}
                handleEdit={handleEdit}
                phoneWS={phoneWS}
                fullNameWS={fullNameWS}
                from_restaurant={from_restaurant}
            />
                
              </FixedWrapper>
              <ModalBackgroundBottom>
          
            <Row>
                <Col xs={12} className='d-flex justify-content-end '>
                <Button className='mt-3' onClick={e => {
                if (creating) {
                    handleSubmit(e);
                } else {
                    handleEdit(e);
                }
                }}
                > 
                { creating ? 'Criar reserva' 
                : reservation && reservation.status === 'Pending_from_customer' && from_restaurant ?
                   <> Aceitar reserva <FontAwesomeIcon icon={faFloppyDisk} style={{color: "#ffffff", marginRight: '5px'}} /></>
                : !from_restaurant ?
                   <> Solicitar alteração <FontAwesomeIcon icon={faFloppyDisk} style={{color: "#ffffff", marginRight: '5px'}} /></>  
                :  <> Guardar <FontAwesomeIcon icon={faFloppyDisk} style={{color: "#ffffff", marginRight: '5px'}} /></>  
                } 
                </Button>
                { cancelledStatuses.includes(reservationObj.status) || creating   ? <div></div>:
                <Button className='mt-3 ms-2' onClick={() => handleShowModal()}
                style={{backgroundColor: '#e05419', color:'#ffffff'}}
                > 
                    Cancelar reserva <FontAwesomeIcon icon={faXmarkSquare} style={{color: "#ffffff", marginRight: '5px'}} />
                </Button>
                
                }
                
                </Col>
              </Row>
            </ModalBackgroundBottom>
            </PopupContainer>
            
            
        </ModalBackground>    

        </SelectWrapper>
        
        }
      
      </>
    :
      <>
      <Wrapper> 
      <h5>Esta a reservar no</h5>
      <Title>{restaurants ? restaurants.restaurant_name : <Loader></Loader>}</Title>
      <StyledCard>
            {currentStep === 1 && (
              <>
                  <CustomDatePicker
                    selectedDate={startDate}
                    onDateChange={(date) => setStartDate(date)}
                    id={restaurant_id}
                  />
                  {startDateWS && 
                      <p style={{color:'red'}}>
                        Falta escolher a data
                      </p>
                  }
                  
                <Button className='mt-3' onClick={goForward}>Próximo</Button>
              </>
            )}

            {currentStep === 2 && (
              <>
              <StepTwoReservations
                startDate={startDate}
                DivFlexStart={DivFlexStart}
                DivFlexMiddle={DivFlexMiddle}
                DateButton={DateButton}
                handlePeopleChange={handlePeopleChange}
                handleOpenDateModal={handleOpenDateModal}
                DropDownButton={DropDownButton}
                limitPeopleBaby={limitPeopleBaby}
                handleShiftChange={handleShiftChange}
                numPeople={numPeople}
                openTime={openTime}
                CustomSelect={CustomSelect}
                shifts={shifts}
                setShift={setShift}
                placeOfTable={placeOfTable}
                setPlaceOfTable={setPlaceOfTable}
                SelectablePopUpOptions={SelectablePopUpOptions}
                handlePlaceOfTable={handlePlaceOfTable}
                handleTimeChange={handleTimeChange}
                time={time}
                setTime={setTime}
                Loader={Loader}
                fullRestaurant={fullRestaurant}
                Messages={Messages}
                ActionButtons={ActionButtons}
                goBack={goBack}
                goForward={goForward}
                editingStartFalse={editingStartFalse}
                token={token}
                placeOfTableWS={placeOfTableWS}
                timeWS={timeWS}
                reservation={reservation}
                update={update}
                setUpdate={setUpdate}
              />
              </>
            )}

          {currentStep === 3 && (
            <>
               <StepThreeReservations
                fullName={fullName}
                setFullName={setFullName}
                StyledPhoneInput={StyledPhoneInput}
                countryCode={countryCode}
                setCountryCodeNumber={setCountryCodeNumber}
                InputOverlay={InputOverlay}
                phoneWithoutCountryCode={phoneWithoutCountryCode}
                handleInputPhoneChange={handleInputPhoneChange}
                Extras={Extras}
                isExtrasOpen={isExtrasOpen}
                handleExtras={handleExtras}
                ExtrasInside={ExtrasInside}
                babyChairs={babyChairs}
                DivFlexStart={DivFlexStart}
                setBabyChairs={setBabyChairs}
                DivFlexEnd={DivFlexEnd}
                ScrollInputOptions={ScrollInputOptions}
                numBabyChairs={numBabyChairs}
                handleBabyChange={handleBabyChange}
                limitPeopleBaby={limitPeopleBaby}
                isMessageOpen={isMessageOpen}
                setIsMessageOpen={setIsMessageOpen}
                isAccessOpen={isAccessOpen}
                message={message}
                setMessage={setMessage}
                maxMessageLength={maxMessageLength}
                CustomSelect={CustomSelect}
                accessabilities={accessabilities}
                setIsAccessOpen={setIsAccessOpen}
                setAccessability={setAccessability}
                ActionButtons={ActionButtons}
                goBack={goBack}
                accessability={accessability}
                setShowTermsAndConditions={setShowTermsAndConditions}
                handleSubmit={handleSubmit}
                phoneWS={phoneWS}
                setEmail={setEmail}
                email={email}
                emailWS={emailWS}
                fullNameWS={fullNameWS}
                true_create_false_edit={true}
                acceptTerms={acceptTerms}
                setAcceptTerms={setAcceptTerms}
                acceptTermsWS={acceptTermsWS}
                from_restaurant={from_restaurant}
            />
          </>
        )}
              
        {currentStep === 4 && (
          <>
            {isLoading ? 
              <h2><Loader></Loader></h2>
            :error && error.includes('reservation-exists') ? 
            <>
              <h2>
                <FontAwesomeIcon size='xl' icon={faTriangleExclamation} style={{color: "#f3e335", marginRight: '15px'}} />
                Já tem um pedido de reserva activo para este dia.
              </h2>
              <h5>Pode alterar a reserva existente no seguinte botão:</h5>

              <Button className='mt-3' onMouseDown={() => handleGoToReservation(error.split(';')[1])} >
                Aceder reserva 
                <FontAwesomeIcon icon={faShareFromSquare} style={{color: "#ffffff", marginLeft:'5px'}} />
              </Button>
            </>
              
            :
            <h2>Por favor atualizar a página.</h2>
            } 
            </>
        )}
      </StyledCard>
      
      {/*<PushNotificationForReservation
        setSubscription={handleNotificationSubscription}
      />*/}
      
      
      

      <TermsAndConditions
        showTermsAndConditions={showTermsAndConditions}
        setSetShowTermsAndConditions={handleTermsAndConditionsFalse}
      />
    </Wrapper>
      
      </>
    }

    {showDateModal &&
      <ModalBackgroundToCustomDate>
        <CustomDateContainer onHide={handleCloseDateModal}>
          <CustomDatePicker
                selectedDate={startDate}
                onDateChange={(date) => setStartDate(date)}
                id={restaurant_id}
                handleCloseDateModal={handleCloseDateModal}
            />
          </CustomDateContainer>
      </ModalBackgroundToCustomDate>
    }


    { tables &&
      <TablesModal
      show={showTablesModal}
      tables_list={tables}
      onClose={() => setShowTablesModal(false)}
      onSave={handleModalSave}
      initialSelectedTables={usedTablesDic}
      token={token}
      id={restaurant_id}
      numPeople={numPeople}
      handleTablesUsedChange={handleTablesUsedChange}
      setSelectedTablesSave={(value) => setSelectedTablesSave(value)}
      setPlaceOfTable={(value) => setPlaceOfTable(value)}
    />
    }
    <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Deseja cancelar a sua reserva?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              {isLoadingCancel?
                <Loader></Loader>:
                <Button variant="secondary" onClick={handleCancel}>
                  Cancelar
                </Button>
              }{/* 
                <Button variant="danger" onClick={handleCloseModal}>
                  Não
                </Button> */}
            </Modal.Footer>
          </Modal>
    </>  
  );
};

export default ReservationScreen;

