import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Loader from '../components/Loader';
import { Modal } from 'react-bootstrap';
import SelectablePopUpOptions from '../components/SelectablePopUpOptions'
import CustomSelect from '../components/SelectableCustom';
import CustomSelectArray from '../components/CustomSelectArray';
import ClosedExceptions from '../components/ClosedExceptions';
import EstimatedTimePage from '../components/EstimatedTimeCustomersSpend';
import Separator from '../components/Separator';
import { Row, Col } from 'react-bootstrap';
import {
  fetchRestaurantOpenDays,
  patchRestaurantOpenDays,
  createRestaurantOpenDays,
  fetchEstimatedTimeCustomersSpend,
  patchEstimatedTimeCustomersSpend,
  createEstimatedTimeCustomersSpend,
  fetchClosedExceptions,
  patchClosedExceptions,
  createClosedExceptions,
  fetchHoursEach,
} from '../actions/restaurantActions';
import { api } from '../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark, faHourglassStart, faHourglassEnd  } from '@fortawesome/free-solid-svg-icons';
import DropDownButton from '../components/DropDownButton';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding:0;
`;

const OpenDaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 2px;
`;

const Weekday = styled.div`
  border: 1px solid black;
  border-radius: 12px;
  width: auto;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background-color: ${(props) => (props.isOpen ? '#03DF90' : 'transparent')};
  margin-right: 4px;
  color: ${(props) => (props.isOpen ? 'white' : 'black')};
  margin-right: 4px;
  margin-bottom: 4px;  // allow vertical spacing for smaller screens
`;


const Box = styled.div`
  margin-top:10px;
  position: relative;
  padding: 20px;
  border: 1px solid;
  border-radius: 5px;
  color: black;
`;

const OpenDay = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  width: 400px;
  min-width: 200px;
  @media (max-width: 576px) {
  width: 100%;   // increase width for smaller screens
  }
`;

const ReservationGrid = styled.div`
  width:100%;
`;

const ReservationCard = styled.div`
background-color: #f8f9fa;
position:relative;
padding: 20px;
border-radius: 8px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
margin-bottom: 20px;
`;


const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #65DDB1;
  border: 1px solid #65DDB1;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  margin-top:10px;
  &:hover {
    background-color: #47A281;
    border-color: #47A281;
  }
`;

const DangerButton = styled(Button)`
  background-color: #ff704d;
  border-color: #ff704d;

  &:hover {
    background-color: #ff471a;
    border-color: #ff471a;
  }
`;
const ActionButtons = styled.div`
  display: flex;
  justify-content:end;
  gap: 10px;
`;

// new stuff

const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:auto;
  padding:10px;
`;


const Title = styled.h6`
  padding: 20px;
  color: white;
`;

const CreateButton = styled(ModernButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const B2 = styled.div`
  background-color: #f8f9fa;
  position:relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const B2Top = styled.div`
  background-color: #58c49f;
  height:30px;
  color:white;
  width:100%;
`;

const B2Bot = styled.div`
  padding: 10px;
  max-height: 250px;
  width: 100%;
  overflow: auto;
  
  /* this is the default color of the scrollbar */
  scrollbar-color: #888 #f2f2f2;
  
  /* this is the width of the scrollbar */
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    /* this is the width of the scrollbar */
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    /* this is the color of the track, where the scrollbar moves */
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    /* this is the color of the scrollbar itself */
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    /* this is the color of the scrollbar when hovered */
    background: #555;
  }
`;

const RemoveButton = styled(DangerButton)`
  position: absolute;
  top: -4px;
  right: 1px;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-size: 18px;
  text-align: center;
  line-height: 5px;
  
`;

const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;

const LargeIconPlus = styled(FontAwesomeIcon)`
  margin-left: 7px; /* adjust this value as needed */
`;



function RestaurantTimeManagement({restaurant_ids=false, handleNextSteps=false, currentStep=false}) {
const [modalIsOpen, setModalIsOpen] = useState(false);
const [timeStart, setTimeStart] = useState('12:00');
const [timeEnd, setTimeEnd] = useState('22:00');
const [shift, setShift] = useState('22:00');
const [shift_pk, setShift_pk] = useState('22:00');
const [shifts, setShifts] = useState(['Breakfast', 'Lunch', 'Dinner']);
const [weekDay, setWeekDay] = useState([]);
const [weekDays, setWeekDays] = useState(['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']);
const [twentyfourtime, setTwentyfourtime] = useState(['00:00', '12:00', '19:00', '22:00']);
const [selectedItems, setSelectedItems] = useState([]);
const [showModal, setShowModal] = useState(false);
const [openShift_id, setOpenShift_id] = useState('');
const [update_delete, setUpdate_delete] = useState('');
const [OpenDaysData, setOpenDaysData] = useState('');

const [Monday, setMonday] = useState(false);
const [Tuesday, setTuesday] = useState(false);
const [Wednesday, setWednesday] = useState(false);
const [Thursday, setThursday] = useState(false);
const [Friday, setFriday] = useState(false);
const [Saturday, setSaturday] = useState(false);
const [Sunday, setSunday] = useState(false);

const dispatch = useDispatch();
const params = useParams();
const [restaurant_id, setRestaurant_id] = useState(restaurant_ids ? restaurant_ids : params.id);

const openDayss = useSelector((state) => state.restaurantOpenDaysOfTheWeek);
const {openDays, isLoadingOpenDays, error} = openDayss
const hoursEachTime = useSelector((state) => state.hoursEachTime);
const {hoursEach} = hoursEachTime
const estimatedTime = useSelector((state) => state.estimatedTimeCustomersSpend.estimatedTime);
const closedExceptions = useSelector((state) => state.closedExceptions.exceptions);
const createSuccess = useSelector((state) => state.createConfirmation.createLoad);



useEffect(() => {
fetchClosedExceptions(restaurant_id)
dispatch(fetchRestaurantOpenDays(restaurant_id));
dispatch(fetchEstimatedTimeCustomersSpend(restaurant_id));
dispatch(fetchClosedExceptions(restaurant_id));
}, [dispatch, restaurant_id]);

useEffect(() => {
  dispatch(fetchHoursEach(restaurant_id));
  }, []);

useEffect(() => {
    setOpenDaysData({
      restaurant:restaurant_id, 
      shift: shift,
      start_time: timeStart,
      end_time: timeEnd,
      Monday: Monday,
      Tuesday: Tuesday,
      Wednesday: Wednesday,
      Thursday: Thursday,
      Friday: Friday,
      Saturday: Saturday,
      Sunday: Sunday,
  });
  }, [dispatch, restaurant_id, shift, timeStart, timeEnd, Monday,
    Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday]);

useEffect(() => {
  const processWeekdays = () => {
    setMonday(selectedItems.includes('Segunda'));
    setTuesday(selectedItems.includes('Terça'));
    setWednesday(selectedItems.includes('Quarta'));
    setThursday(selectedItems.includes('Quinta'));
    setFriday(selectedItems.includes('Sexta'));
    setSaturday(selectedItems.includes('Sábado'));
    setSunday(selectedItems.includes('Domingo'));
  };

  processWeekdays();
}, [selectedItems]);

const handleStartTimeChange = (newTime) => {
    setTimeStart(newTime);
  };

const handleEndTimeChange = (newTime) => {
    setTimeEnd(newTime);
};
const handleShiftChange = (value) => {
    setShift(value);
  };
  const handleSelectChange = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };

const openDayElements = Array.isArray(openDays) ?
    openDays.map((openDay) => (
        <B2 key={openDay.id}>
          <B2Top>
            <ZoneTitle>
              {openDay.shift === 'Lunch' ? 'Almoço' 
              : openDay.shift === 'Dinner' ? 'Jantar' 
              : openDay.shift === 'Breakfast' ? 'Pequeno almoço' 
              :  openDay.shift}
            </ZoneTitle>
          </B2Top>
        <B2Bot>
          <Container>
            <Row className='mb-3' style={{width: '100%'}}>
              <Col xs={5} md={4}>

                <FontAwesomeIcon icon={faHourglassStart} style={{color: "#7ce4ae",  marginRight:'5px'}} /> 
                  Início: {openDay.start_time.length > 5 ? openDay.start_time.slice(0,5) : openDay.start_time}

              </Col>
              <Col xs={5} md={4}>

                  <FontAwesomeIcon icon={faHourglassEnd} style={{color: "#7ce4ae", marginRight: '5px'}}/>
                  Fim: {openDay.end_time.length > 5 ? openDay.end_time.slice(0,5) : openDay.end_time}

              </Col>
            </Row>
          </Container>
          
        <OpenDaysContainer>
            <Weekday isOpen={openDay.Monday}>segunda</Weekday>
            <Weekday isOpen={openDay.Tuesday}>terça</Weekday>
            <Weekday isOpen={openDay.Wednesday}>quarta</Weekday>
            <Weekday isOpen={openDay.Thursday}>quinta</Weekday>
            <Weekday isOpen={openDay.Friday}>sexta</Weekday>
            <Weekday isOpen={openDay.Saturday}>sábado</Weekday>
            <Weekday isOpen={openDay.Sunday}>domingo</Weekday>
        </OpenDaysContainer>
        <ActionButtons>
          <Button 
            onClick={() => handleOpenTimeModal('update', openDay.id, openDay.shift, 
            openDay.start_time, openDay.end_time,openDay.Monday, openDay.Tuesday, 
            openDay.Wednesday,openDay.Thursday, openDay.Friday, openDay.Saturday, 
            openDay.Sunday)}
          >
            <FontAwesomeIcon size="xl" icon={faGear} />
          </Button>
          <RemoveButton variant="danger" onClick={() => handleShowModal(openDay.id, 'delete')} style={{marginRight: '5px'}}>
                  x
          </RemoveButton>
        </ActionButtons>

        </B2Bot>
        
        
        </B2>
    ))
    :null;

const handleFormSubmit = (e) => {
    e.preventDefault();
    const openDaysData = {
      restaurant:restaurant_id, 
      shift: shift,
      start_time: timeStart,
      end_time: timeEnd,
      Monday: Monday,
      Tuesday: Tuesday,
      Wednesday: Wednesday,
      Thursday: Thursday,
      Friday: Friday,
      Saturday: Saturday,
      Sunday: Sunday,
    };
    if (update_delete == 'update') {
      dispatch(patchRestaurantOpenDays(1, shift_pk, openDaysData));
    } else {
      dispatch(createRestaurantOpenDays(restaurant_id,  openDaysData));
    }
    setModalIsOpen(false);
  };

    const deleteOpenDay = async (openDayId, delete_update) => {
      try {
        const response = await api.delete(
          `restaurants/1/opentimes/${openDayId}/`
        );
      } catch (error) {
      }

      dispatch(fetchRestaurantOpenDays(restaurant_id));
      setShowModal(false);
    };
    
    const handleModalClose = () => {
      setShowModal(false);
    };
    const handleOpenTimeModal = (
      delete_or_update, shift_id, shift, startTime, endTime, Monday,
      Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday
    ) => {
      setUpdate_delete(delete_or_update);
      if (delete_or_update == 'update') {
        setShift_pk(shift_id)
        setShift(shift);
        setTimeStart(startTime.length > 5 ? startTime.slice(0,5) : startTime);
        setTimeEnd(endTime.length > 5 ? endTime.slice(0,5) : endTime );
        const myList = [];
        if (Monday) {
          myList.push('Segunda');
        }
        if (Tuesday) {
          myList.push('Terça');
        }
        if (Wednesday) {
          myList.push('Quarta');
        }
        if (Thursday) {
          myList.push('Quinta');
        }
        if (Friday) {
          myList.push('Sexta');
        }
        if (Saturday) {
          myList.push('Sábado');
        }
        if (Sunday) {
          myList.push('Domingo');
        }
        setSelectedItems(myList);
        
      }
      setModalIsOpen(true);
    }

    const handleShowModal = (shift_id) => {
      setShowModal(true);
      setOpenShift_id(shift_id)
    };
  
  

return (
          <div>
          {currentStep === 'shifts' ||  currentStep === false ?
          <>
          <Wrapper>
            <WrapperTop>
              <Title>Turnos</Title>

            </WrapperTop>
              
              <WrapperBot>
                {openDays? <div>{openDayElements || (isLoadingOpenDays ? <Loader /> : <div>Nada para ver aqui</div>)}</div> : <Loader />} 
              </WrapperBot>
              
              <CreateButton onMouseDown={() => handleOpenTimeModal('criar')}>Criar turno <LargeIconPlus size="xl" icon={faPlus} /></CreateButton>
          </Wrapper>
          </>
          :
          <></>
          }
          
          <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
            <Modal.Header>
              {update_delete == 'update'?<h5>Editar turno</h5>:<h5>Criar turno</h5> }
            </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleFormSubmit}>
                <div>
                  <label htmlFor="shift">Nome do turno</label>
                  <CustomSelect
                          items={shifts}
                          onSelectChange={handleShiftChange}
                          selectedItem={shift}
                          setItem={setShift}
                        />
                </div>
                {/* Add the remaining form fields here */}
                <Row>
                  <Col xs={6}  md={4}>
                    <div>
                    <label htmlFor="start_time">Hora de início:</label>
                      <DropDownButton
                        options={hoursEach}
                        handleOptionChange={handleStartTimeChange}
                        initialOption={timeStart}
                      />
                   </div>

                  </Col>
                  <Col xs={6} md={4}>
                    <div>
                    <label htmlFor="end_time">Hora do fim:</label>
                    <DropDownButton
                      options={hoursEach}
                      handleOptionChange={handleEndTimeChange}
                      initialOption={timeEnd}
                    />
                  </div>
                  </Col>
                </Row>
                
                
              
              
                <div>
                <label htmlFor="end_time">Dias da semana</label>
                
                <CustomSelectArray
                          items={weekDays}
                          onSelectChange={handleSelectChange}
                          selectedItems={selectedItems}
                          wrap={true}
                        />
                </div>
                <Modal.Footer>
                <Button type="submit">Guardar</Button>
                <Button type="button" onClick={() => setModalIsOpen(false)}>Cancelar</Button>
                </Modal.Footer>
          </form>
          </Modal.Body>
              
          </Modal>
          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Apagar turno.</Modal.Title>
            </Modal.Header>
            <Modal.Body>De certeza que quer apagar o turno?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                fechar
              </Button>
                <Button variant="danger" onClick={() => deleteOpenDay(openShift_id)}>
                  Apagar
                </Button>     
            </Modal.Footer>
          </Modal>
          </div>

)};


export default RestaurantTimeManagement